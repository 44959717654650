<div class="container-fluid full-width-image">
  <div class="auth-container">
    <div class="auth-box">
      <div class="container">
        <div class="card col-md-6 mt-3 mx-auto">
          <div class="card-body">
            <h4 class="card-title text-center">
              <fa-icon icon="user-circle" size="3x"></fa-icon>
            </h4>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
