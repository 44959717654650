<div class="wrapper">
  <div class="toolbar">
    <mat-toolbar color="primary">
      <div class="container">
        <span class="branding spacer center d-none d-sm-inline d-md-none">
          Limitless Landing Page
        </span>
        <span class="branding spacer d-none d-md-inline">
          Limitless Landing Page
        </span>
        <span class="d-none d-md-inline">
          <button mat-button class="nav-button" *ngFor="let item of navItems" [routerLink]="item.link"
            routerLinkActive="active">
            {{ item.title }}
          </button>

          <mat-slide-toggle [checked]="isDarkTheme$ | async" (change)="toggleTheme($event.checked)">Change Theme
          </mat-slide-toggle>
        </span>
        <div style="float: right;">
          <a [href]="repoUrl" target="_blank">Fork on GitHub
            <fa-icon [icon]="['fab','github']"></fa-icon>
          </a>
        </div>
      </div>
    </mat-toolbar>
  </div>
</div>
