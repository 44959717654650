<footer class="footer">
  <hr>
  <div class="container">
    <span class="text-muted">
      &copy; {{currentYear}}
      <a href="https://mathisgarberg.no">Mathis Garberg</a>,
      <a href="mailto:tom.h.anderson@gmail.com">Tom H Anderson</a>,
        and contributors.
      <a href="https://github.com/mathisGarberg/limitless-landingPage">
        https://github.com/mathisGarberg/limitless-landingPage
      </a>
    </span>
  </div>
</footer>
